import React from "react";

import "../components/style.scss";
import Helmet from "../components/helmet";
import Navbar from "../components/navbar";
import LinkList from "../components/linklist";
import Footer from "../components/footer";
import EmailSignup from "../widgets/email-management/mailing-list";

import { graphql } from "gatsby";
import IAllMarkdownRemark from "../utils/markdown-types/markdown-remark";


let initialState = {
  mailingActive: false
};

let defaultProps = {
  callbackFromParent: (msg: string, value: any) => { },
  data: {
    allMarkdownRemark: {
      edges: []
    }
  }
}

type State = Readonly<typeof initialState>
type Props = Readonly<typeof defaultProps>

class BlogPage extends React.Component<Props, State> {
  state = initialState;
  handleClick() {
    let a = function () {
      console.log('Click happened');
    }
    a();
  }
  parentCallback = (msg: string, value: any) => {
    if (msg === "mailingActive") {
      if (value === true) {
        this.setState({ mailingActive: true });
      }
    }
  }
  render() {
    let key = 'blog';
    let allMarkdownRemark: IAllMarkdownRemark = this.props.data.allMarkdownRemark;
    let edges = allMarkdownRemark.edges.filter((edge) => edge.node.fields.slug.indexOf(key) > -1);
    console.log(edges);
    return (
      <div className="w-full flex justify-center">
        <div className="max-w-screen-lg">
          <Helmet />
          <Navbar />
          <LinkList
            title="Blog"
            edges={edges}
          />
          <Footer />
        </div>
      </div>
    )
  }
};

export default BlogPage;
export const query = graphql`
  query BlogListQuery {
    site {
      siteMetadata {
        description
      }
    }
    allMarkdownRemark {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
              date
              author
              keywords
              excerpt
              keywords
            }
          }
        }
      }
  }
`
import React from "react";
import "./style.scss";
import { Link } from 'gatsby';

const LinkList = ({ title, edges }) => {
    console.log(edges);
    return (
        <div className="flex flex-col items-center justify-center w-full max-w-screen-lg px-1 py-10">
            <div className="w-full flex flex-col">
                <h1 className="text-4xl tracking-wider uppercase mx-2  my-0 py-2">{title}</h1>

                {edges.map(({ node }, i) => (
                    <div className="shadow mx-2 px-4 mt-2 py-4 rounded-lg">
                        <Link to={node.fields.slug} className="" >
                            <div className="flex flex-row flex-wrap">
                                <div className="opensans text-xs text-orange-500 hover:text-black no-underline">{node.frontmatter.keywords.split(',')[0].toUpperCase()}</div>
                            </div>
                            <div className="simplifica flex-wrap font-bold uppercase tracking-widest text-2xl text-red-800 my-0 py-0">
                                {node.frontmatter.title} </div>
                            <div className="flex flex-col mb-4">
                                <div className="font-condensed text-xs font-bold text-gray-800">{node.frontmatter.date}</div>
                                <div className="font-condensed text-xs font-bold text-gray-800">By {node.frontmatter.author}</div>
                            </div>
                            <div className="opensans text-sm md:text-md leading-snug text-gray-800 font-hairline">{node.frontmatter.excerpt}</div>
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default LinkList;